import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui/components'
import {
	Button,
	Icon,
	Link,
	Popover,
	StatusIndicator
} from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import useApis from '../../hooks/query/useApis'
import type { Api } from '../../types/apis'
import CONSTANTS from '../../constants'

const ApiOnboarding: FC = () => {
	const navigate = useNavigate()

	const { data: apis, isLoading } = useApis()

	// if (!platforms || isLoading) return <LoadingSpinner />
	const columnDefinitions = [
		{
			id: 'apiID',
			width: 100,
			header: 'ID',
			cell: (data: any) => (
				<Link
					onFollow={() => {
						navigate(data.apiID)
					}}
				>
					{data.apiID}
				</Link>
			),
			sortingField: 'apiID'
		},
		{
			id: 'apiName',
			width: 100,
			header: 'API Name',
			cell: (data: Api) => data.apiName || '',
			sortingField: 'apiName'
		},
		{
			id: 'requestedById',
			width: 100,
			header: 'Requested by',
			cell: (data: Api) => data.requestorID || '',
			sortingField: 'requestedById'
		},
		{
			id: 'creationTime',
			width: 100,
			header: 'Creation Time',
			cell: (data: Api) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime)
					const year = date.getFullYear()
					const month = (date.getMonth() + 1).toString().padStart(2, '0')
					const day = date.getDate().toString().padStart(2, '0')
					const hours = date.getHours().toString().padStart(2, '0')
					const minutes = date.getMinutes().toString().padStart(2, '0')
					const seconds = date.getSeconds().toString().padStart(2, '0')

					return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
				}
				return ''
			},
			sortingField: 'creationTime'
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: Api) => {
				const status = data.status // if (!applications || isLoading) return <LoadingSpinner />
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			},
			sortingField: 'status'
		}
	]

	const tableActions = (
		<div
			style={{
				display: 'flex',
				width: 130
			}}
		>
			<Button variant="primary" onClick={() => navigate('/apis/new')}>
				New API
			</Button>
			<div
				style={{
					marginLeft: 7,
					marginTop: 4
				}}
			>
				<Popover
					fixedWidth
					position="right"
					triggerType="custom"
					content={
						<p>
							Click the following link for a step-by-step guide on API
							onboarding:
							<a
								href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_onboarding/`}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Plus"
								style={{
									marginLeft: '5px',
									marginTop: '25px'
								}}
							>
								LINK
							</a>
						</p>
					}
				>
					<Icon name="status-info" />
				</Popover>
			</div>
		</div>
	)
	return (
		<Table
			header="APIs"
			columnDefinitions={columnDefinitions}
			items={apis || []}
			actions={tableActions}
			disableRowSelect
			sortingDisabled={false}
			loading={isLoading}
		/>
	)
}

export default ApiOnboarding

import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Alert,
	Box,
	Button,
	ButtonDropdown,
	Container,
	ContentLayout,
	Grid,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import ApprovalDetails from '../../../components/Microservice/ApprovalDetails'
import ApprovalForm from '../../../components/Microservice/ApprovalForm'
import RejectionForm from '../../../components/Microservice/RejectionForm'
import LoadingSpinner from '../../../components/LoadingSpinner'
import CONSTANTS from '../../../constants'
import RequiredPermissions from '../../../helper/RequiredPermissions'
import CommentsOverview from '../../../components/Microservice/CommentsOverview'
import RenderMicroserviceFields from './RenderMicroserviceFields'
import { Microservice } from '../../../types/microservices'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
import AccessDetails from '../../../components/Microservice/AccessDetails'
import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsReadGCP'
import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEditGCP'
import useSingleMicroservice from '../../../hooks/query/useSingleMicroservice'

const MicroserviceDetails: FC = () => {
	const CURRENT_USER_ID = JSON.parse(localStorage['okta-token-storage'])[
		'idToken'
	]['claims']['email']
	const { id } = useParams() as { id: string }
	const navigate = useNavigate()
	const { data: thisMicroservice, isLoading } = useSingleMicroservice(id)
	const thisMicroserviceID = thisMicroservice?.microserviceID || ''
	const {
		data: userPermissions,
		isLoading: isLoadingPermissions,
		error: permissionsError
	} = useUserPermissions(CURRENT_USER_ID)
	const hasSpecificPermissions = (
		permissions: string[],
		requiredPermissions: string[]
	): boolean => {
		return requiredPermissions.some((requiredPerm) =>
			permissions.includes(requiredPerm)
		)
	}

	// Commenting to update here
	const specificPermissions = [
		'microservices:*:*',
		'microservices:*:approve',
		'*:*:*'
	]
	const hasRequiredPermissions =
		userPermissions &&
		hasSpecificPermissions(userPermissions.permissions, specificPermissions)
	const [showApprovalForm, setShowApprovalForm] = useState(false)
	const [showRejectionForm, setShowRejectionForm] = useState(false)
	const [showButton, setShowButton] = useState(true)
	function handleShowAccessDetails(): void {
		setShowButton(!showButton)
	}

	if (!thisMicroservice || isLoading) return <LoadingSpinner />

	if (thisMicroservice.status === 'ERROR') {
		return (
			<Alert
				statusIconAriaLabel="Error"
				type="error"
				header="An error occurred"
			>
				{thisMicroservice.details.message}
			</Alert>
		)
	}
	const handleApproveClick = () => {
		setShowApprovalForm(true)
		setShowRejectionForm(false)
	}

	const handleRejectClick = () => {
		setShowRejectionForm(true)
		setShowApprovalForm(false)
	}
	const microserviceVersionedObject = getKeyValueMicroservice(thisMicroservice)

	console.log(thisMicroservice)
	// let isAllTagsApproved = false
	// if (thisMicroservice.tags !== undefined) {
	// 	isAllTagsApproved = true
	// 	let platformTags = thisPlatform.tags
	// 	let platformTagsApprovalStatuses = platformTags.approvalStatus
	// 	for (const tagStatus of platformTagsApprovalStatuses) {
	// 		if (tagStatus.status !== 1) {
	// 			isAllTagsApproved = false
	// 		}
	// 	}
	// }

	// console.log(isAllTagsApproved)
	return (
		<ContentLayout
			header={
				<HeaderWithStatusBadges
					headerName={thisMicroservice?.microserviceName}
					status={thisMicroservice?.status}
				/>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 3, xxs: 9 } },
					{ colspan: { default: 9, xxs: 3 } }
				]}
			>
				<SpaceBetween direction="vertical" size="l">
					<RenderMicroserviceFields
						Microservice={microserviceVersionedObject}
					/>

					{/* Commenting to update here */}
					{/* {!hasRequiredPermissions &&
						thisMicroservice.status === 'PENDING_APPROVAL' && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.MICROSERVICE_REQUESTOR}
							>
								<Container header={<Header variant="h3">Tag Details</Header>}>
									{thisMicroservice.tagsID && (
										<ReadGenericTags tagsId={thisPlatform.tagsID} />
									)}
								</Container>
							</RequiredPermissions>
						)} */}

					{/* Commenting to update here */}
					{/* {(thisMicroservice.status === 'APPROVED' ||
						thisMicroservice.status === 'REJECTED') && (
						<RequiredPermissions
							requiredPermissions={CONSTANTS.MICROSERVICE_REQUESTOR}
						>
							<Container header={<Header variant="h3">Tag Details</Header>}>
								{thisMicroservice.tagsID && (
									<ReadGenericTags tagsId={thisMicroservice.tagsID} />
								)}
							</Container>
						</RequiredPermissions>
					)} */}

					{/* Commenting to update here */}
					{/* {thisMicroservice.status === 'PENDING_APPROVAL' &&
						hasRequiredPermissions && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.MICROSERVICE_APPROVER}
							>
								<Container header={<Header variant="h3">Tag Details</Header>}>
									{thisMicroservice.tagsID && (
										<EditGenericTags
											tagsId={thisMicroservice.tagsID}
											isPvm={false}
										/>
									)}
								</Container>
							</RequiredPermissions>
						)} */}

					{/* Commenting to update here */}
					{/* {thisMicroservice.status === 'PENDING_APPROVAL' &&
						CURRENT_USER_ID !== thisMicroservice.requestorID && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.MICROSERVICE_APPROVER}
							>
								<Box float="left">
									<ButtonDropdown
										items={
											isAllTagsApproved
												? [
														{ text: 'To Approve', id: 'to-approve' },
														{ text: 'To Reject', id: 'to-reject' }
												  ]
												: [{ text: 'To Reject', id: 'to-reject' }]
										}
										onItemClick={({ detail }) => {
											if (detail.id === 'to-approve') {
												setShowApprovalForm(true)
												setShowRejectionForm(false)
											}

											if (detail.id === 'to-reject') {
												setShowApprovalForm(false)
												setShowRejectionForm(true)
											}
										}}
										variant="primary"
									>
										Action
									</ButtonDropdown>
								</Box>
							</RequiredPermissions>
						)} */}
					{showApprovalForm && (
						<ApprovalForm
							thisMicroserviceID={thisMicroserviceID}
							shouldShowApprovalForm={setShowApprovalForm}
						/>
					)}

					{showRejectionForm && (
						<RejectionForm
							thisMicroserviceID={thisMicroserviceID}
							shouldShowRejectionForm={setShowRejectionForm}
						/>
					)}
					{thisMicroservice.status === 'APPROVED' && (
						<RequiredPermissions
							requiredPermissions={CONSTANTS.MICROSERVICE_APPROVER}
						>
							<ApprovalDetails approvalDetails={thisMicroservice!} />
						</RequiredPermissions>
					)}

					{thisMicroservice.status === 'APPROVED' &&
						CURRENT_USER_ID !== thisMicroservice.requestorID &&
						showButton && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.APPLICATION_APPROVER}
							>
								<Button variant="primary" onClick={handleShowAccessDetails}>
									View Access Details
								</Button>
							</RequiredPermissions>
						)}

					{!showButton && (
						<AccessDetails
							microserviceName={thisMicroservice?.microserviceName}
							// Commenting to update here
							// env={thisPlatform?.environment}
						/>
					)}
				</SpaceBetween>

				<SpaceBetween direction="vertical" size="l">
					<Container header={<Header variant="h3">Comment History</Header>}>
						<CommentsOverview comments={thisMicroservice.comments} />
					</Container>
				</SpaceBetween>
			</Grid>
		</ContentLayout>
	)
}

const getKeyValueMicroservice = (microservice: Microservice) => {
	console.log(microservice, 'microservice')
	const baseEntries = [
		{
			label: 'Requestor',
			value: microservice?.requestorID,
			version: getVersionStr(microservice?.version, 'requestorID')
		},
		{
			label: 'Microservice Name',
			value: microservice?.microserviceName,
			version: getVersionStr(microservice?.version, 'microserviceName')
		},
		{
			label: 'Application Name',
			value: microservice?.applicationName,
			version: getVersionStr(microservice?.version, 'applicationName')
		},
		{
			label: 'Programming Language',
			value: microservice?.programmingLanguage,
			version: getVersionStr(microservice?.version, 'programmingLanguage')
		},
		{
			label: 'Application URL',
			value: microservice?.applicationUrl,
			version: getVersionStr(microservice?.version, 'applicationUrl')
		},
		{
			label: 'Port Number',
			value: microservice?.portNumber,
			version: getVersionStr(microservice?.version, 'portNumber')
		},
		{
			label: 'Microservice Endpoint',
			value: microservice?.microserviceEndpoint,
			version: getVersionStr(microservice?.version, 'microserviceEndpoint')
		},
		{
			label: 'WBS Code',
			value:
				microservice?.wbsCodeId === 0 ? 'Use Existing' : microservice?.wbsCode,
			version: getVersionStr(microservice?.version, 'wbsCode')
		},
		{
			label: 'Cost Center',
			value:
				microservice?.costCenterId === 0
					? 'Use Existing'
					: microservice?.costCenter,
			version: getVersionStr(microservice?.version, 'costCenter')
		}
		// {
		// 	label: 'Platform Owner',
		// 	value: platform?.platformOwner,
		// 	version: getVersionStr(platform?.version, 'platformOwner')
		// },
		// {
		// 	label: 'Group',
		// 	value: platform?.group,
		// 	version: getVersionStr(platform?.version, 'group')
		// },
		// {
		// 	label: 'System Alias',
		// 	value: platform?.systemAlias,
		// 	version: getVersionStr(platform?.version, 'systemAlias')
		// },
		// {
		// 	label: 'GCP Project ID',
		// 	value: platform?.gcpProjectID,
		// 	version: getVersionStr(platform?.version, 'gcpProjectID')
		// },
		// {
		// 	label: 'Platform Type',
		// 	value: platform?.platformType,
		// 	version: getVersionStr(platform?.version, 'platformType')
		// },
		// {
		// 	label: 'Platform Size',
		// 	value: platform?.platformSize,
		// 	version: getVersionStr(platform?.version, 'platformSize')
		// },
		// {
		// 	label: 'Environment',
		// 	value: platform?.environment,
		// 	version: getVersionStr(platform?.version, 'environment')
		// },
		// {
		// 	label: 'Region',
		// 	value: platform?.region,
		// 	version: getVersionStr(platform?.version, 'region')
		// }
	]

	// const additionalEntries = platform?.minNode
	// 	? [
	// 			{
	// 				label: 'Minimum Node',
	// 				value: platform?.minNode,
	// 				version: getVersionStr(platform?.version, 'minNode')
	// 			},
	// 			{
	// 				label: 'Maximum Node',
	// 				value: platform?.maxNode,
	// 				version: getVersionStr(platform?.version, 'maxNode')
	// 			},
	// 			{
	// 				label: 'Machine Type',
	// 				value: platform?.machineType,
	// 				version: getVersionStr(platform?.version, 'machineType')
	// 			}
	// 	  ]
	// 	: []
	// return [...baseEntries, ...additionalEntries]
	return [...baseEntries]
}

const getVersionStr = (version: Array<any> | undefined, key: string) => {
	let versionItems: string[] = []
	version?.forEach((element) => {
		if (!element[key]) return
		if (key === 'region' && element.region) {
			versionItems.unshift(`v${element.versionID}: ${element.region[0].label}`)
		} else {
			versionItems.unshift(`v${element.versionID}: ${element[key]}`)
		}
	})
	return versionItems
}

export default MicroserviceDetails

import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ApiEditRequest } from '../../types/apis'
import api from '../../api'

const useEditApi = (apiID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (apiEdit: ApiEditRequest) => {
			return api.apis.editApi(apiEdit)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['apis'] })
			queryClient.invalidateQueries({
				queryKey: ['apis', apiID]
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})
}

export default useEditApi

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProjectResourceApprovalRequest } from "../../types/projectResource";
import api from "../../api";

const useAcceptProjectResource = (projectResourceID: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (projectResourceApproval: ProjectResourceApprovalRequest) => {
      return api.projects.resources.approveResource(projectResourceApproval)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projectResources'] })
      queryClient.invalidateQueries({ queryKey: ['projectResources', projectResourceID] })
    }
  })
}

export default useAcceptProjectResource
import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import { ProjectResource } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const getSingleProjectResource = async(projectResourceID: string): Promise<{statusCode: number, body: ProjectResource | { message: string }}> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}?projectResourceID=${projectResourceID}`)

  if (response.status !== 200 && response.body !== undefined) {
    let body = await response.json()
    return {
      statusCode: response.status,
      body: body as { message: string }
    }
  }

  return await response.json()
}

export default getSingleProjectResource
import { useQuery } from '@tanstack/react-query'

import type { Microservice } from '../../types/microservices'
import api from '../../api'

const useMicroservices = () => {
	return useQuery({
		queryKey: ['microservices'],
		queryFn: () => api.microservices.getMicroservices(),
		select(data) {
			return data.body as Array<Microservice>
		}
	})
}

export default useMicroservices

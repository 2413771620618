import React, { FC } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useParams } from 'react-router-dom'
import { Button, Container, Form, SpaceBetween } from '@cloudscape-design/components'
import { useForm } from 'react-hook-form'

import useAcceptProject from '../../../hooks/mutate/useAcceptProject'
import useSingleProject from '../../../hooks/query/useSingleProject'
import { useApprovalFormStore } from '../../../stores/projects/approvalFormStore'
import type { ProjectApprovalRequest } from '../../../types/projects'

interface ApprovalFormProps {
    thisProjectID: string
    shouldShowApprovalForm: (shouldShow: boolean) => void
}

const EphemeralApprovalForm: FC<ApprovalFormProps> = ({ thisProjectID, shouldShowApprovalForm }) => {
    const { authState } = useOktaAuth()
    const thisUserEmail = authState?.idToken?.claims.email as string
    const { id } = useParams() as { id: string }
    const { data: thisProject } = useSingleProject(id, true)

    const { actions: { clearFormValues } } = useApprovalFormStore()

    const { trigger, formState: { isValid } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const { mutate: acceptProject, isLoading } = useAcceptProject(thisProjectID)

    const handleApprove = () => {
        trigger()
        if (!isValid) return

        const approvalRequest: ProjectApprovalRequest = {
            action: 'APPROVE',
            projectID: thisProjectID,
            approverID: thisUserEmail,
            details: {}
        }

        acceptProject(approvalRequest, {
            onSuccess: () => {
                shouldShowApprovalForm(false)
                clearFormValues()
            }
        })
    }

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        loading={isLoading}
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>
                </SpaceBetween>
            }
        >
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    <h2>Do you want to accept this ephemeral project form?</h2>
                </SpaceBetween>
            </Container>
        </Form>
    )
}

export default EphemeralApprovalForm
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ApiRequest } from '../../types/apis'
import api from '../../api'

const useCreateNewApi = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (apiReq: ApiRequest) => {
			return api.apis.createNewApi(apiReq)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['apis'] })
		}
	})
}

export default useCreateNewApi

import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type EditApiFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type EditApiFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
		setOriginalRequestFormValues: (
			originalApiRequestValues: EditApiFormState
		) => void
	}
}

// Commenting to update here
const initialFormValues = {
	creationTime: '2023-06-28T09:37:54.867631',
	requestorID: 'ceso-isg-ece-gcptribe@globe.com.ph',
	comments: [
		{
			commenterID: 'ceso-isg-ece-gcptribe@globe.com.ph',
			comment: 'test',
			timestamp: '2023-06-28T09:38:35.944395'
		}
	],
	commentText: '',
	lastUpdatedTime: '2023-06-28T09:38:35.944406',
	details: {
		microserviceName: 'test-microservice',
		apiName: 'test-api',
		apiEndpoint: '/test',
		httpMethod: 'GET',
		apiStatus: 'Design',
		description: '',
		requestDataTypes: '',
		mockRequest: '',
		responseDataTypes: '',
		mockResponse: ''
	},
	version: [
		{
			versionId: 1,
			comments: [
				{
					commenterID: 'ceso-isg-ece-gcptribe@globe.com.ph',
					comment: 'test',
					timestamp: '2023-06-28T09:38:35.944395'
				}
			],
			lastUpdatedTime: '2023-06-28T11:38:35.944406'
		}
	]

	// platformID: '0fab9663-cb4d-408a-8060-b1c323ee3518',
	// creationTime: '2023-06-28T09:37:54.867631',
	// requestorID: 'ceso-isg-ece-gcptribe@globe.com.ph',
	// status: 'REJECTED',
	// platformName: 'quick',
	// comments: [
	// 	{
	// 		commenterID: 'ceso-isg-ece-gcptribe@globe.com.ph',
	// 		comment: 'test',
	// 		timestamp: '2023-06-28T09:38:35.944395'
	// 	}
	// ],
	// commentText: '',
	// lastUpdatedTime: '2023-06-28T09:38:35.944406',
	// details: {
	// 	environment: 'prod',
	// 	gcpProjectName: '',
	// 	projectEditor: 'gitlabs-app-prd-adm@globe.com.ph',
	// 	projectViewer: 'gitlabs-app-prd-adm@globe.com.ph',
	// 	region: [
	// 		{
	// 			label: 'Singapore',
	// 			value: 'singapore',
	// 			description: 'ap-southeast-1'
	// 		}
	// 	],
	// 	platformName: '',
	// 	platformAlias: '',
	// 	projectKey: '',
	// 	platformOwner: '',
	// 	wbsCode: '',
	// 	costCenter: '',
	// 	systemType: '',
	// 	department: ''
	// },
	// version: [
	// 	{
	// 		versionId: 1,

	// 		region: [
	// 			{
	// 				label: 'Singapore',
	// 				value: 'singapore',
	// 				description: 'ap-southeast-1'
	// 			}
	// 		],
	// 		lastUpdatedTime: '2023-06-28T09:38:35.944406'
	// 	},
	// 	{
	// 		versionId: 2,
	// 		comments: [
	// 			{
	// 				commenterID: 'ceso-isg-ece-gcptribe@globe.com.ph',
	// 				comment: 'test',
	// 				timestamp: '2023-06-28T09:38:35.944395'
	// 			}
	// 		],
	// 		lastUpdatedTime: '2023-06-28T11:38:35.944406'
	// 	}
	// ]
}

const editApiFormStore = create<EditApiFormState & EditApiFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: initialFormValues })),

			setOriginalRequestFormValues: (originalApiRequestValues) =>
				set(() => ({
					formValues: originalApiRequestValues.formValues
				})),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						draft.formValues[fieldName] = value
					})
				)
		}
	})
)

export const useEditApiFormStore = () => editApiFormStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('EditApiFormStore', editApiFormStore)
}

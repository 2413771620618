import {
	Alert,
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	FormField,
	Header,
	Icon,
	Popover,
	RadioGroup,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOktaAuth } from '@okta/okta-react'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'
import LoadingSpinner from '../../../components/LoadingSpinner'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'
import { useEditApiFormStore } from '../../../stores/apis/editApiFormStore'
import useSingleApi from '../../../hooks/query/useSingleApi'
import useEditApi from '../../../hooks/mutate/useEditApi'
import { ApiEditRequest } from '../../../types/apis'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import FormDatePicker from '../../../components/ControlledFormInputs/FormDatePicker'
import CONSTANTS from '../../../constants'

const ENVIRONMENT_LIST = [
	{ label: 'dv', value: 'dev' },
	{ label: 'st', value: 'staging' },
	{ label: 'pd', value: 'prod' }
]

const apiKeys = [
	'Microservice Name',
	'Api Name',
	'Api Endpoint',
	'HTTP Method',
	'Status',
	'Description',
	'Request Data Types',
	'Mock Request',
	'Response Data Types',
	'Mock Response'
]

const MICROSERVICE_LIST = [
	{ label: 'microservice1', value: 'microservice1' },
	{ label: 'microservice2', value: 'microservice2' },
	{ label: 'microservice3', value: 'microservice3' }
]

const METHOD_LIST = [
	{ label: 'GET', value: 'GET' },
	{ label: 'POST', value: 'POST' },
	{ label: 'PUT', value: 'PUT' },
	{ label: 'PATCH', value: 'PATCH' },
	{ label: 'DELETE', value: 'DELETE' }
]

const EditApiForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const { id } = useParams() as { id: string }

	const { data: originalApiRequest, isLoading: isLoadingSingleApi } =
		useSingleApi(id)

	const navigate = useNavigate()

	console.log('You are on the right page')
	useEffect(() => {
		if (typeof originalApiRequest === 'object' && originalApiRequest !== null) {
			const prefetchedObject = {
				...originalApiRequest.details,
				commentText: ''
			}
			setOriginalRequestFormValues({
				formValues: prefetchedObject,
				isFormValid: true
			})
			reset(prefetchedObject)
		}
	}, [originalApiRequest])

	const {
		formValues,
		actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
	} = useEditApiFormStore()

	const {
		mutate: editApi,
		isLoading: isLoadingEditApi,
		isError: isErrorInEditReqeust
	} = useEditApi(id)

	const {
		control,
		reset,
		trigger,
		formState: { isValid, dirtyFields }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: { ...formValues.details, commentText: '' }
	})

	useEffect(() => {
		setFormValidity(isValid)
	}, [isValid, setFormValidity])

	const getOriginalObjVersion = () => {
		let deltaObject: any = {}
		const updatedFields: string[] = Object.keys(dirtyFields)
		for (let fieldName of updatedFields) {
			if (fieldName !== 'commentText')
				deltaObject[fieldName] =
					originalApiRequest?.details[fieldName as keyof object]
		}
		if (Object.keys(deltaObject).length !== 0) {
			deltaObject['lastUpdatedTime'] = originalApiRequest?.lastUpdatedTime
		}
		return deltaObject
	}

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let formFieldValues = JSON.parse(JSON.stringify(formValues))
		let apiEditRequest: ApiEditRequest = {
			details: formFieldValues,
			apiID: originalApiRequest?.apiID || '',
			requestorID: originalApiRequest?.requestorID || '',
			creationTime: originalApiRequest?.creationTime || '',
			action: 'UPDATE',
			version: getOriginalObjVersion(),
			commenterID: thisUserEmail,
			comment: formValues.commentText,
			status: 'PENDING_APPROVAL'
		}
		delete apiEditRequest.details.commentText

		editApi(apiEditRequest, {
			onSuccess: () => navigate('/apis'),
			onError: (e) => {}
		})
	}

	if (!originalApiRequest || isLoadingSingleApi) return <LoadingSpinner />

	return (
		<>
			<Form
				actions={
					<SpaceBetween direction="horizontal" size="l">
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoadingEditApi}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</SpaceBetween>
				}
				header={
					<Header variant="h1" description="">
						<SpaceBetween direction="horizontal" size="l">
							<Button
								variant="icon"
								iconName="arrow-left"
								onClick={() => navigate(-1)}
								key="icon"
							/>
							<div key="text">{originalApiRequest?.details.ApiName}</div>
						</SpaceBetween>
					</Header>
				}
			>
				<SpaceBetween direction="vertical" size="l">
					<Container>
						<SpaceBetween direction="vertical" size="l">
							<ColumnLayout columns={2} variant="default">
								<div
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<div style={{ width: '650px' }}>
										<FormSelect
											label="Microservice"
											name="microservice"
											control={control}
											rules={{
												required: 'Please Select Option'
											}}
											setFormValues={setFormValues}
											options={MICROSERVICE_LIST}
											placeholder="Select Microservice Name"
										/>
									</div>
									<div style={{ marginLeft: '10px', marginTop: '25px' }}>
										<Popover
											fixedWidth
											header="Platform GCP Project ID tip"
											position="right"
											triggerType="custom"
											content={
												<p>
													Please see guide on how to enter your GCP Project ID:
													<a
														href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
														target="_blank"
														rel="noopener noreferrer"
														aria-label="Plus"
														style={{
															marginLeft: '10px',
															marginTop: '25px'
														}}
													>
														LINK
													</a>
												</p>
											}
										>
											<Icon name="status-info" />
										</Popover>
									</div>
								</div>
								<div></div>
								<FormInput
									name="name"
									label="Name"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Display Name of API"
									description="Max 20 characters"
									rules={{
										required: 'Please enter API name',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'API name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
								<FormInput
									name="path"
									label="Path"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Endpoint Path"
									description="The endpoint URL/path"
									rules={{
										required: 'Please enter system alias',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
								<FormSelect
									label="HTTP Method"
									name="httpMethod"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={METHOD_LIST}
									placeholder="Select HTTP Method"
								/>
								<FormInput
									name="status"
									label="Status"
									type="text"
									control={control}
									setFormValues={setFormValues}
									placeholder="Design"
									disabled
									rules={{
										required: 'Please enter system alias',
										minLength: {
											value: 5,
											message: 'Minumum length of 5 characters'
										},
										pattern: {
											value: /^[a-z0-9]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
							</ColumnLayout>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									Description
								</label>
								<Textarea
									onChange={({ detail }) => console.log('description')}
									value={''}
									placeholder="Enter description of Endpoint"
								/>
							</div>
							<ColumnLayout columns={2} variant="default">
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<label style={{ fontWeight: '600', marginBottom: '5px' }}>
										Request Data Types
									</label>
									<Textarea
										onChange={({ detail }) => console.log('description')}
										value={''}
										placeholder="Enter Request Structure as JSON object with data type as a value"
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<label style={{ fontWeight: '600', marginBottom: '5px' }}>
										Mock Request
									</label>
									<Textarea
										onChange={({ detail }) => console.log('description')}
										value={''}
										placeholder="Enter Request Structure as JSON object with actual values"
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<label style={{ fontWeight: '600', marginBottom: '5px' }}>
										Response Data Types
									</label>
									<Textarea
										onChange={({ detail }) => console.log('description')}
										value={''}
										placeholder="Enter Response Structure JSON object with data type as a value"
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<label style={{ fontWeight: '600', marginBottom: '5px' }}>
										Mock Response
									</label>
									<Textarea
										onChange={({ detail }) => console.log('description')}
										value={''}
										placeholder="Enter Response Structure JSON object with actual values"
									/>
								</div>
							</ColumnLayout>

							<FormRequiredTags
								name="apiDetails"
								label="Api Details"
								control={control}
								tagKeys={apiKeys}
								setFormValues={setFormValues}
								rules={{
									required: `Please enter the required value`,
									pattern: {
										value: /^(?!\s)(.*\S)?(?<!\s)$/,
										message: 'Leading and trailing whitespaces are not allowed.'
									}
								}}
							/>
							<FormField label="" description="">
								<ColumnLayout columns={3} variant="default">
									<FormInput
										name="budget"
										label="Platform Budget Allocated"
										description="In US Dollar (USD) currency"
										type="text"
										rules={{
											required: 'Please enter Platform Budget allocated',
											pattern: {
												value: /^\d+$/,
												message:
													'Please enter valid Platform Budget allocated without spaces'
											}
										}}
										control={control}
										setFormValues={setFormValues}
										placeholder="0"
									/>
									<FormDatePicker
										name="crfsDate"
										label="CRFS Date"
										description="Customer Ready for Service Date"
										rules={{
											required: 'Please Enter CRFS Date'
										}}
										control={control}
										setFormValues={setFormValues}
									/>
								</ColumnLayout>
							</FormField>
						</SpaceBetween>
					</Container>
					<Container
						header={<Header variant="h3">Comment History</Header>}
						footer={
							<SpaceBetween direction="vertical" size="l">
								<FormInputArea
									name="commentText"
									label=""
									description="Add comments here"
									control={control}
									rules={{
										required: 'Please enter your comments here',
										minLength: {
											value: 2,
											message: 'Minimum comment length should be 2'
										},
										maxLength: {
											value: 50,
											message: 'Maximum comment length should be 50'
										}
									}}
									setFormValues={setFormValues}
								/>
							</SpaceBetween>
						}
					></Container>
					{isErrorInEditReqeust && (
						<Container>
							<Box variant="p">
								<Alert
									statusIconAriaLabel="Error"
									type="error"
									header="Error Submitting Request, Please try again."
								/>
							</Box>
						</Container>
					)}
				</SpaceBetween>
			</Form>
		</>
	)
}

export default EditApiForm

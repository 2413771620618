import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Alert,
	Box,
	Button,
	ButtonDropdown,
	Container,
	ContentLayout,
	Grid,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import ApprovalDetails from '../../../components/Api/ApprovalDetails'
import ApprovalForm from '../../../components/Api/ApprovalForm'
import RejectionForm from '../../../components/Api/RejectionForm'
import LoadingSpinner from '../../../components/LoadingSpinner'
import CONSTANTS from '../../../constants'
import RequiredPermissions from '../../../helper/RequiredPermissions'
import CommentsOverview from '../../../components/Api/CommentsOverview'
import RenderApiFields from './RenderApiFields'
import { Api } from '../../../types/apis'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
import AccessDetails from '../../../components/Api/AccessDetails'
import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsReadGCP'
import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEditGCP'
import useSingleApi from '../../../hooks/query/useSingleApi'

const ApiDetails: FC = () => {
	const CURRENT_USER_ID = JSON.parse(localStorage['okta-token-storage'])[
		'idToken'
	]['claims']['email']
	const { id } = useParams() as { id: string }
	const navigate = useNavigate()
	const { data: thisApi, isLoading } = useSingleApi(id)
	const thisApiID = thisApi?.apiID || ''
	const {
		data: userPermissions,
		isLoading: isLoadingPermissions,
		error: permissionsError
	} = useUserPermissions(CURRENT_USER_ID)
	const hasSpecificPermissions = (
		permissions: string[],
		requiredPermissions: string[]
	): boolean => {
		return requiredPermissions.some((requiredPerm) =>
			permissions.includes(requiredPerm)
		)
	}

	// Commenting to update here
	const specificPermissions = ['apis:*:*', 'apis:*:approve', '*:*:*']
	const hasRequiredPermissions =
		userPermissions &&
		hasSpecificPermissions(userPermissions.permissions, specificPermissions)
	const [showApprovalForm, setShowApprovalForm] = useState(false)
	const [showRejectionForm, setShowRejectionForm] = useState(false)
	const [showButton, setShowButton] = useState(true)
	function handleShowAccessDetails(): void {
		setShowButton(!showButton)
	}

	if (!thisApi || isLoading) return <LoadingSpinner />

	if (thisApi.status === 'ERROR') {
		return (
			<Alert
				statusIconAriaLabel="Error"
				type="error"
				header="An error occurred"
			>
				{thisApi.details.message}
			</Alert>
		)
	}
	const handleApproveClick = () => {
		setShowApprovalForm(true)
		setShowRejectionForm(false)
	}

	const handleRejectClick = () => {
		setShowRejectionForm(true)
		setShowApprovalForm(false)
	}
	const apiVersionedObject = getKeyValueApi(thisApi)

	console.log(thisApi)
	// let isAllTagsApproved = false
	// if (thisMicroservice.tags !== undefined) {
	// 	isAllTagsApproved = true
	// 	let platformTags = thisPlatform.tags
	// 	let platformTagsApprovalStatuses = platformTags.approvalStatus
	// 	for (const tagStatus of platformTagsApprovalStatuses) {
	// 		if (tagStatus.status !== 1) {
	// 			isAllTagsApproved = false
	// 		}
	// 	}
	// }

	// console.log(isAllTagsApproved)
	return (
		<ContentLayout
			header={
				<HeaderWithStatusBadges
					headerName={thisApi?.apiName}
					status={thisApi?.status}
				/>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 3, xxs: 9 } },
					{ colspan: { default: 9, xxs: 3 } }
				]}
			>
				<SpaceBetween direction="vertical" size="l">
					<RenderApiFields Api={apiVersionedObject} />

					{/* Commenting to update here */}
					{!hasRequiredPermissions && thisApi.status === 'PENDING_APPROVAL' && (
						<RequiredPermissions requiredPermissions={CONSTANTS.API_REQUESTOR}>
							{/* <Container header={<Header variant="h3">Tag Details</Header>}>
									{thisApi.tagsID && (
										<ReadGenericTags tagsId={thisApi.tagsID} />
									)}
								</Container> */}
						</RequiredPermissions>
					)}

					{/* Commenting to update here */}
					{(thisApi.status === 'APPROVED' || thisApi.status === 'REJECTED') && (
						<RequiredPermissions requiredPermissions={CONSTANTS.API_REQUESTOR}>
							{/* <Container header={<Header variant="h3">Tag Details</Header>}>
								{thisApi.tagsID && (
									<ReadGenericTags tagsId={thisApi.tagsID} />
								)}
							</Container> */}
						</RequiredPermissions>
					)}

					{/* Commenting to update here */}
					{thisApi.status === 'PENDING_APPROVAL' && hasRequiredPermissions && (
						<RequiredPermissions requiredPermissions={CONSTANTS.API_APPROVER}>
							{/* <Container header={<Header variant="h3">Tag Details</Header>}>
									{thisApi.tagsID && (
										<EditGenericTags
											tagsId={thisApi.tagsID}
											isPvm={false}
										/>
									)}
								</Container> */}
						</RequiredPermissions>
					)}

					{/* Commenting to update here */}
					{/* {thisApi.status === 'PENDING_APPROVAL' &&
						CURRENT_USER_ID !== thisApi.requestorID && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.API_APPROVER}
							>
								<Box float="left">
									<ButtonDropdown
										items={
											isAllTagsApproved
												? [
														{ text: 'To Approve', id: 'to-approve' },
														{ text: 'To Reject', id: 'to-reject' }
												  ]
												: [{ text: 'To Reject', id: 'to-reject' }]
										}
										onItemClick={({ detail }) => {
											if (detail.id === 'to-approve') {
												setShowApprovalForm(true)
												setShowRejectionForm(false)
											}

											if (detail.id === 'to-reject') {
												setShowApprovalForm(false)
												setShowRejectionForm(true)
											}
										}}
										variant="primary"
									>
										Action
									</ButtonDropdown>
								</Box>
							</RequiredPermissions>
						)} */}
					{showApprovalForm && (
						<ApprovalForm
							thisApiID={thisApiID}
							shouldShowApprovalForm={setShowApprovalForm}
						/>
					)}

					{showRejectionForm && (
						<RejectionForm
							thisApiID={thisApiID}
							shouldShowRejectionForm={setShowRejectionForm}
						/>
					)}
					{thisApi.status === 'APPROVED' && (
						<RequiredPermissions requiredPermissions={CONSTANTS.API_APPROVER}>
							<ApprovalDetails approvalDetails={thisApi!} />
						</RequiredPermissions>
					)}

					{thisApi.status === 'APPROVED' &&
						CURRENT_USER_ID !== thisApi.requestorID &&
						showButton && (
							<RequiredPermissions requiredPermissions={CONSTANTS.API_APPROVER}>
								<Button variant="primary" onClick={handleShowAccessDetails}>
									View Access Details
								</Button>
							</RequiredPermissions>
						)}

					{!showButton && (
						<AccessDetails
							apiName={thisApi?.apiName}
							// Commenting to update here
							// env={thisPlatform?.environment}
						/>
					)}
				</SpaceBetween>

				<SpaceBetween direction="vertical" size="l">
					<Container header={<Header variant="h3">Comment History</Header>}>
						<CommentsOverview comments={thisApi.comments} />
					</Container>
				</SpaceBetween>
			</Grid>
		</ContentLayout>
	)
}

const getKeyValueApi = (api: Api) => {
	console.log(api, 'api')
	const baseEntries = [
		{
			label: 'Requestor',
			value: api?.requestorID,
			version: getVersionStr(api?.version, 'requestorID')
		},
		{
			label: 'Api Name',
			value: api?.apiName,
			version: getVersionStr(api?.version, 'apiName')
		},
		{
			label: 'Microservice Name',
			value: api?.microserviceName,
			version: getVersionStr(api?.version, 'microserviceName')
		},
		{
			label: 'Api Endpoint',
			value: api?.apiEndpoint,
			version: getVersionStr(api?.version, 'applicationUrl')
		},
		{
			label: 'HTTP Method',
			value: api?.httpMethod,
			version: getVersionStr(api?.version, 'httpMethod')
		},
		{
			label: 'Status',
			value: api?.apiStatus,
			version: getVersionStr(api?.version, 'apiStatus')
		},
		{
			label: 'Description',
			value: api?.description,
			version: getVersionStr(api?.version, 'description')
		},
		{
			label: 'Request Data Types',
			value: api?.requestDataTypes,
			version: getVersionStr(api?.version, 'requestDataTypes')
		},
		{
			label: 'Mock Request',
			value: api?.mockRequest,
			version: getVersionStr(api?.version, 'mockRequest')
		},
		{
			label: 'Response Data Types',
			value: api?.responseDataTypes,
			version: getVersionStr(api?.version, 'responseDataTypes')
		},
		{
			label: 'Mock Response',
			value: api?.mockResponse,
			version: getVersionStr(api?.version, 'mockResponse')
		}
	]

	// const additionalEntries = platform?.minNode
	// 	? [
	// 			{
	// 				label: 'Minimum Node',
	// 				value: platform?.minNode,
	// 				version: getVersionStr(platform?.version, 'minNode')
	// 			},
	// 			{
	// 				label: 'Maximum Node',
	// 				value: platform?.maxNode,
	// 				version: getVersionStr(platform?.version, 'maxNode')
	// 			},
	// 			{
	// 				label: 'Machine Type',
	// 				value: platform?.machineType,
	// 				version: getVersionStr(platform?.version, 'machineType')
	// 			}
	// 	  ]
	// 	: []
	// return [...baseEntries, ...additionalEntries]
	return [...baseEntries]
}

const getVersionStr = (version: Array<any> | undefined, key: string) => {
	let versionItems: string[] = []
	version?.forEach((element) => {
		if (!element[key]) return
		if (key === 'region' && element.region) {
			versionItems.unshift(`v${element.versionID}: ${element.region[0].label}`)
		} else {
			versionItems.unshift(`v${element.versionID}: ${element[key]}`)
		}
	})
	return versionItems
}

export default ApiDetails

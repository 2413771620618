import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProjectResourceRejectionRequest } from "../../types/projectResource";
import api from "../../api";

const useRejectProjectResource = (projectResourceID: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (projectResourceRejection: ProjectResourceRejectionRequest) => {
      return api.projects.resources.rejectResource(projectResourceRejection)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projectResources'] })
      queryClient.invalidateQueries({ queryKey: ['projectResources', projectResourceID] })
    }
  })
}

export default useRejectProjectResource
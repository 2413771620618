import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import { ApiRequest } from '../../types/apis'

const { APIS_API_URL } = CONSTANT

const createNewApiEndpoint = async (
	newApi: ApiRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${APIS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'CREATE'
		},
		body: JSON.stringify(newApi)
	})

	return response.json()
}

export default createNewApiEndpoint

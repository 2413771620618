import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { MicroserviceRejectionRequest } from '../../types/microservices'
import api from '../../api'

const useRejectMicroservice = (microserviceID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (microserviceRejection: MicroserviceRejectionRequest) => {
			return api.microservices.rejectMicroservice(microserviceRejection)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['microservices'] })
			queryClient.invalidateQueries({
				queryKey: ['microservices', microserviceID]
			})
		}
	})
}

export default useRejectMicroservice

import { useQuery } from "@tanstack/react-query";

import api from "../../api";

const useProjectResources = (projectId: string) => {
  return useQuery({
    queryKey: ['projectResources'],
    queryFn: () => api.projects.resources.getProjectResources(projectId),
    select(data) {
      return data
    }
  })
}

export default useProjectResources
import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { MicroserviceEditRequest } from '../../types/microservices'
import api from '../../api'

const useEditMicroservice = (microserviceID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (microserviceEdit: MicroserviceEditRequest) => {
			return api.microservices.editMicroservice(microserviceEdit)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['microservices'] })
			queryClient.invalidateQueries({
				queryKey: ['microservices', microserviceID]
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})
}

export default useEditMicroservice

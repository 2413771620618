import { FC } from 'react'
import { Box, ColumnLayout, SpaceBetween } from '@cloudscape-design/components'
import useSingleTags from '../../../hooks/query/useSingleTags'
import LoadingSpinner from '../../LoadingSpinner'
import ApproverOverview from '../ApproverOverview'
import { keysToLabel } from '../../../helper/toCamelCase'

interface ReadGenericTagsProps {
	tagsId: string
	layout?: number
}

const ReadGenericTagsEphemeral: FC<ReadGenericTagsProps> = ({ tagsId, layout = 2 }) => {
	const { data: originalTags, isLoading: isLoadingTags } = useSingleTags(tagsId)

	if (!originalTags || isLoadingTags) return <LoadingSpinner />
    
	return (
		<>
			<SpaceBetween direction="vertical" size="s">
				<ApproverOverview
					approvalStatus={originalTags['approvalStatus']}
					isDisabled={true}
				/>
			</SpaceBetween>
		</>
	)
}

export default ReadGenericTagsEphemeral

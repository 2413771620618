import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type NewApiFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type NewApiFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

// Confirm on initialFormValues for api
// Commenting to update here
const initialFormValues = {
	microserviceName: '',
	apiName: '',
	apiEndpoint: '',
	httpMethod: '',
	apiStatus: 'Design',
	description: '',
	requestDataTypes: '',
	mockRequest: '',
	responseDataTypes: '',
	mockResponse: ''
}

const newApiFormStore = create<NewApiFormState & NewApiFormActions>(
	(set, _get) => ({
		isFormValid: false,
		formValues: initialFormValues,
		actions: {
			clearFormValues: () => set(() => ({ formValues: initialFormValues })),

			setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

			setFormValues: (fieldName, value) =>
				set((state) =>
					produce(state, (draft) => {
						// if (fieldName === 'platformViewer') {
						// 	const emails = value.split(',').map((email: string) => email.trim())
						// 	draft.formValues[fieldName] = emails
						// } else {
						// 	draft.formValues[fieldName] = value
						// }

						if (
							fieldName === 'applicationName' ||
							fieldName === 'programmingLanguage'
						) {
							draft.formValues[fieldName] = value.value
						} else {
							draft.formValues[fieldName] = value
						}
					})
				)
		}
	})
)

export const useNewApiFormStore = () => newApiFormStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('NewApiFormStore', newApiFormStore)
}

import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import { UpdateProjectResourceApprovalStatus } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const updateApprovers = async (updateApprovers: UpdateProjectResourceApprovalStatus): Promise<any> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json-patch+json',
      'action': 'UPDATE_APPROVAL'
    },
    body: JSON.stringify(updateApprovers)
  })

  return response.json()
}

export default updateApprovers

import { FC, useEffect, useState } from 'react'
import {
    Alert,
    Button,
    ColumnLayout,
    Header
} from '@cloudscape-design/components'
import FormInput from '../Input'
import { useForm } from 'react-hook-form'
import FormSelect from '../Select'
import { useEditTagsManagementFormStore } from '../../../stores/tags/editTagsManagementFormStore'
import useSingleTags from '../../../hooks/query/useSingleTags'
import LoadingSpinner from '../../LoadingSpinner'
 
import { UpdateTags } from '../../../types/tags'
import useUpdateTags from '../../../hooks/mutate/useUpdateTags'
import ApproverOverview from '../ApproverOverview'
import { useOktaAuth } from '@okta/okta-react' 
 
interface EditGenericTagsProps {
    tagsId: string
    layout_col?: number
    page_context?: string
}
 
const EditGenericTagsEphemeral: FC<EditGenericTagsProps> = ({
    tagsId,
    layout_col = 2
}) => {
    const { authState } = useOktaAuth()
    const thisUserEmail = authState?.idToken?.claims.email as string
 
    const { data: originalTags, isLoading: isLoadingTags } = useSingleTags(tagsId)
 
    const {
        formValues,
        actions: { setFormValues, setFormValidity, setOriginalRequestFormValues }
    } = useEditTagsManagementFormStore()
 
    const { mutate: updateTagsById, isLoading: isLoadingUpdateTags } =
        useUpdateTags(tagsId)
 
    const {
        control,
        reset,
        setValue,
        formState: { isValid, dirtyFields }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formValues.details,
        shouldUseNativeValidation: true
    })
 
    useEffect(() => {
        if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'opex') {
            setValue('WBSCode', '')
            setFormValues('WBSCode', '')
        } else if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'capex') {
            setValue('costCenter', '')
            setFormValues('costCenter', '')
        }
    }, [formValues.fundingSourceType, setValue, setFormValues])
 
    useEffect(() => {
        setFormValidity(isValid)
    }, [isValid, setFormValidity])
 
    const [notification, setNotification] = useState<any>({
        type: '',
        statusIconAriaLabel: '',
        msg: ''
    })
    const [showTagsNotification, setShowTagsNotification] = useState(false)
 
    useEffect(() => {
        if (typeof originalTags === 'object' && originalTags !== null) {
            setOriginalRequestFormValues({
                formValues: originalTags.details,
                isFormValid: false
            })
            reset(originalTags.details)
        }
        // eslint-disable-next-line
    }, [originalTags])
 
    if (!originalTags || isLoadingTags) return <LoadingSpinner />
 
    const saveUpdatedTags = () => {
        console.log('save Updated Tags')
        const updateTagRequest: UpdateTags = {
            tagsID: tagsId,
            requestorID: thisUserEmail,
            action: 'UPDATE_TAGS',
            status: 'PENDING_APPROVAL',
            details: formValues
        }
        updateTagsById(updateTagRequest, {
            onSuccess: () => {
                setNotification({
                    type: 'success',
                    statusIconAriaLabel: 'Success',
                    msg: 'Tags Status Updated Successfully'
                })
                setShowTagsNotification(true)
                setTimeout(() => {
                    setShowTagsNotification(false)
                }, 3000)
            },
            onError: () => {
                setNotification({
                    type: 'error',
                    statusIconAriaLabel: 'Error',
                    msg: 'Error saving Tags, Please try again!'
                })
                setShowTagsNotification(true)
                setTimeout(() => {
                    setShowTagsNotification(false)
                }, 3000)
            }
        })
    }
 
    return (
        <>
            <ApproverOverview
                approvalStatus={originalTags['approvalStatus']}
                tagsID={tagsId}
                description="Tick checkBox to Provide approval"
                //isDisabled={page_context == 'PROJECT' ? true : false}
            />
        </>
    )
}
 
export default EditGenericTagsEphemeral
import { useQuery } from '@tanstack/react-query'

import type { Api } from '../../types/apis'
import api from '../../api'

const useApis = () => {
	return useQuery({
		queryKey: ['apis'],
		queryFn: () => api.apis.getApis(),
		select(data) {
			return data.body as Array<Api>
		}
	})
}

export default useApis

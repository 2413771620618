import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ProjectResource, ProjectResourceRequest } from '../../types/projectResource'
import api from '../../api'

const useCreateNewProjectResource = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (resource: ProjectResourceRequest) => {
      return api.projects.resources.createNewResource(resource)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projectResources', 'projects'] })
    }
  })
}

export default useCreateNewProjectResource
import {
	Box,
	Container,
	Header,
	ColumnLayout
} from '@cloudscape-design/components'
import { FC } from 'react'

interface ValueWithLabelProps {
	label: string
	value: string
	version: string[]
}

interface ApiProps {
	Api: Record<any, any>
}

const ValueWithLabel: FC<ValueWithLabelProps> = ({ label, value, version }) => {
	return (
		<div>
			<Box variant="awsui-key-label">{label}</Box>
			<div>{value}</div>
			{version.map((item, index) => (
				<div key={index}>
					<s>{item}</s>
				</div>
			))}
		</div>
	)
}

const RenderApiFields: FC<ApiProps> = ({ Api }) => {
	return (
		<>
			<Container>
				<Header variant="h3">Request Details</Header>
				<ColumnLayout columns={3} variant="text-grid">
					{Api.map((element: ValueWithLabelProps, index: number) => {
						return <ValueWithLabel {...element} key={element.label + index} />
					})}
				</ColumnLayout>
			</Container>
		</>
	)
}

export default RenderApiFields

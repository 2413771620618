import { useMutation } from "@tanstack/react-query"
import { UpdateProjectResourceApprovalStatus } from "../../types/projectResource"
import api from "../../api"

const usePatchResourceApproval = (projectResourceID: string) => {
  return useMutation({
    mutationFn: (updateApprovers: UpdateProjectResourceApprovalStatus) => {
      return api.projects.resources.updateApprovers(updateApprovers)
    },
    onSuccess: () => {},
    onError: (error) => {
      console.log(error)
    }
  })
}

export default usePatchResourceApproval
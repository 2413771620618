import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleProject from "../../../../hooks/query/useSingleProject";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { ProjectResource } from "../../../../types/projectResource";
import { StatusIndicator, Link, Button } from "@cloudscape-design/components";
import { Table } from '@aws-northstar/ui/components'
import useProjectResources from "../../../../hooks/query/useProjectResources";


const SslCerts: FC = () => {
  const { id: projectId } = useParams() as { id: string }

  const { data: project, isLoading: isProjectLoading } = useSingleProject(projectId)

  const { data: projectResources, isLoading } = useProjectResources(projectId)

  const navigate = useNavigate()

  if (isLoading || isProjectLoading) {
    return <LoadingSpinner/>
  }

  if (project === undefined || (projectResources?.body === undefined)) {
    return (
      <>
        <h1>Project Resource Not Found</h1>
      </>
    )
  }

  const columnDefinitions = [
    {
      id: 'sslCertID',
      width: 100,
      header: 'ID',
      cell: (data: ProjectResource) => (
        <Link onFollow={() => navigate(`/projects/${projectId}/sslCerts/${data.projectResourceID}`)}>{data.projectResourceID}</Link>
      )
    },
    {
      id: 'status',
      width: 100,
      header: 'Status',
      cell: (data: ProjectResource) => {
        const status = data.status
        switch (status) {
          case 'PENDING_APPROVAL':
            return (
              <StatusIndicator type="in-progress" colorOverride="blue">
                Pending Approval
              </StatusIndicator>
            )
          case 'APPROVED':
            return <StatusIndicator type="success">Approved</StatusIndicator>
          case 'REJECTED':
            return <StatusIndicator type="error">Rejected</StatusIndicator>
          default:
            return null
        }
      }
    }
  ]

  const tableActions = (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Button variant="primary" onClick={() => navigate(`/projects/${projectId}/sslCerts/new`)}>
        New SSL Certificate
      </Button>
    </div>
  )


  return (
    <Table
      header={`SSL Certificates for ${[project.details.projectName]}`}
      columnDefinitions={columnDefinitions}
      items={projectResources?.body || []}
      actions={tableActions}
      disableRowSelect
      sortingDisabled/>
  )
}

export default SslCerts
import React, { FC, useState } from 'react'
import {
  Alert,
  ColumnLayout,
  Grid,
  Header,
  RadioGroup
} from '@cloudscape-design/components'
import { TagsApproval } from '../../../types/tags'
import useMultiStepApprovalTags from '../../../hooks/mutate/useMultiStepApprovalTags'
import { useOktaAuth } from '@okta/okta-react'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
import usePatchResourceApproval from '../../../hooks/mutate/usePatchResourceApproval'
import { ProjectResourceApproval, UpdateProjectResourceApprovalStatus } from '../../../types/projectResource'
interface ProjectResourceApprovalProps {
  approvalStatus: Array<ProjectResourceApproval> | []
  isDisabled?: boolean
  projectResourceID?: string
  description?: string
}

const ApproverOverviewLocal: FC<ProjectResourceApprovalProps> = ({
  approvalStatus,
  isDisabled = false,
  projectResourceID= ''
}) => {
  const { authState } = useOktaAuth()
  const thisUserEmail = authState?.idToken?.claims.email as string

  const { mutate: patchResourceApprovals } = usePatchResourceApproval(projectResourceID)
  const { data: access } = useUserPermissions(thisUserEmail)

  const [notification, setNotification] = useState<any>({
    type: '',
    statusIconAriaLabel: '',
    msg: ''
  })
  const [showApprovalNotification, setShowApprovalNotification] =
    useState(false)

  const [approvalStatusLocal, setApprovalStatus] = useState(approvalStatus)

  const updateApprovalStatus = (state: any, tagkey: string) => {
    let updatedApprovalList: Array<ProjectResourceApproval> = []
    approvalStatusLocal.forEach((item) => {
      if (item.name === tagkey) {
        updatedApprovalList.push({
          ...item,
          status:
            state.value === 'default' ? null : state.value === 'true' ? 1 : 0,
          approverID: thisUserEmail,
          lastUpdatedTime: Date.now() + ''
        })
      } else {
        updatedApprovalList.push(item)
      }
    })

    setApprovalStatus(updatedApprovalList)

    let patch: UpdateProjectResourceApprovalStatus = {
      action: 'UPDATE_APPROVAL',
      projectResourceID: projectResourceID,
      approvalStatus: updatedApprovalList.find(
        (item) => item.name === tagkey
      )
    }

    patchResourceApprovals(patch, {
      onSuccess: () => {
        console.log('success')
        setNotification({
          type: 'success',
          statusIconAriaLabel: 'Success',
          msg: 'Tags Approval Status Updated!'
        })
        setShowApprovalNotification(true)
        setTimeout(() => {
          setShowApprovalNotification(false)
        }, 3000)
      },
      onError: (e) => {
        setNotification({
          type: 'error',
          statusIconAriaLabel: 'Error',
          msg: 'Error saving Tags, Please try again!'
        })
        setShowApprovalNotification(true)
        setTimeout(() => {
          setShowApprovalNotification(false)
        }, 3000)
      }
    })
  }
  return (
    <>
      <Header variant="h3" description="">
        Approval Status
      </Header>
      <ColumnLayout columns={2} variant="text-grid">
        {approvalStatusLocal &&
          approvalStatusLocal.map((approverItem) => {
            return (
              <Grid
                disableGutters
                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                key={approverItem.name}
              >
                <div color="text-body-secondary" key={approverItem.name}>
                  {approverItem.name}
                </div>

                <RadioGroup
                  onChange={({ detail }) => {
                    updateApprovalStatus(detail, approverItem.name)
                  }}
                  value={
                    approverItem.status === null
                      ? 'default'
                      : Boolean(approverItem.status) === true
                        ? 'true'
                        : 'false'
                  }
                  items={
                    isDisabled
                      ? [
                        {
                          value: 'default',
                          label: 'Pending Action',
                          disabled: true
                        },
                        {
                          value: 'true',
                          label: 'Approved',
                          disabled: true
                        },
                        {
                          value: 'false',
                          label: 'Rejected',
                          disabled: true
                        }
                      ]
                      : [
                        {
                          value: 'default',
                          label: 'Pending Action',
                          disabled: true
                        },
                        {
                          value: 'true',
                          label: 'Approve',
                          disabled: !access?.permissions?.includes(
                            `projectResources:${approverItem.name}:approve`
                          )
                        },
                        {
                          value: 'false',
                          label: 'Reject',
                          disabled: !access?.permissions?.includes(
                            `projectResources:${approverItem.name}:approve`
                          )
                        }
                      ]
                  }
                />
                <Grid>
                  <div>
                    {approverItem.status === 1 && <p>Approved By  :  {approverItem.approverID}</p>}
                    {approverItem.status === 0 && <p>Rejected By :  {approverItem.approverID}</p>}
                  </div>
                </Grid>
              </Grid>
            )
          })}
      </ColumnLayout>
      {showApprovalNotification && (
        <>
          <br />
          <Alert
            statusIconAriaLabel={notification.statusIconAriaLabel}
            type={notification.type}
          >
            {notification.msg}
          </Alert>
        </>
      )}
    </>
  )
}

const ApproverOverview = React.memo(ApproverOverviewLocal)
export default ApproverOverview

import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import { ProjectResourceApprovalRequest } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const approveResource = async(resourceApproval: ProjectResourceApprovalRequest): Promise<any> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      action: 'APPROVE'
    },
    body: JSON.stringify(resourceApproval)
  })

  return response.json()
}

export default approveResource
import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ApiRejectionRequest } from '../../types/apis'
import api from '../../api'

const useRejectApi = (apiID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (apiRejection: ApiRejectionRequest) => {
			return api.apis.rejectApi(apiRejection)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['apis'] })
			queryClient.invalidateQueries({
				queryKey: ['apis', apiID]
			})
		}
	})
}

export default useRejectApi

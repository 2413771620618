import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { ApiEditRequest } from '../../types/apis'

const { APIS_API_URL } = CONSTANT

const editApi = async (editApi: ApiEditRequest): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(`${APIS_API_URL}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			action: 'UPDATE'
		},
		body: JSON.stringify(editApi)
	})

	return response.json()
}

export default editApi

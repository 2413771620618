import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import { ProjectResourceRejectionRequest } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const rejectResource = async (resourceRejection: ProjectResourceRejectionRequest): Promise<any> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      action: 'REJECT'
    },
    body: JSON.stringify(resourceRejection)
  })

  return response.json()
}

export default rejectResource
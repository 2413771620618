import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import { ProjectResource, ProjectResourceRequest } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const createNewResource = async (newResource: ProjectResourceRequest): Promise<any> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      action: 'CREATE'
    },
    body: JSON.stringify(newResource)
  })

  return response.json()
}

export default createNewResource
import {
	Box,
	Button,
	Checkbox,
	Container,
	Form,
	Header,
	Icon,
	Popover,
	SpaceBetween
} from '@cloudscape-design/components'
import { useOktaAuth } from '@okta/okta-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import { ApplicationRequest } from '../../../types/applications'
import { useNewApplicationFormStore } from '../../../stores/applications/newApplicationFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewApplication from '../../../hooks/mutate/useCreateNewApplication'
import usePlatforms from '../../../hooks/query/usePlatforms'
import useApplications from '../../../hooks/query/useApplications'
import { v4 as uuidv4 } from 'uuid'
import { getTagsApprovalObject, TagsDetails, TagsRequest } from '../../../types/tags'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import api from '../../../api'
import GcpApplicationTagsNew from './GcpApplicationNewTags'

const NewApplicationForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string
	const navigate = useNavigate()
	const { data: platforms } = usePlatforms()
	const [platformOptions, setPlatformOptions] = useState<
		{ label: string; value: string }[]
	>([])

	const { data: applications } = useApplications()
	const [envOptions, setEnvOptions] = useState<
		{ label: string; value: string }[]
	>([])

	interface envOpt {
		label: string
		value: string
	}

	const [curEnv, setCurEnv] = useState('')

	// const ENVIRONMENT_LIST = [
	// 	{ label: 'dev', value: 'dev' },
	// 	{ label: 'staging', value: 'staging' },
	// 	{ label: 'prod', value: 'prod' }
	// ]

	const { mutate: createNewApplication, isLoading } = useCreateNewApplication()
	const { mutate: createTags, isLoading: isCreateTagsLoading } = useCreateNewTags()
	const [showForm, setShowForm] = useState(false)
	const [submitValue, setSubmitValue] = useState(false)
	const [additionalEnvName, setAdditionalEnvName] = useState('')
	const [isInheritTagsChecked, setInheritTagsChecked] = useState(false)

	const handleYesClick = () => {
		setShowForm(true)
	}

	const handleNoClick = () => {
		navigate('/platforms')
	}
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewApplicationFormStore()

	const { 
		formValues: tagFormValues,
		actions: {
			setFormValues: setTagFormValues,
			clearFormValues: clearTagFormValues
		}
	 } = useGenericTagsManagementFormStore()

	const {
		control,
		trigger,
		setValue,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	useEffect(() => {
		if (platforms && platforms.length > 0) {
			const filteredPlatforms = platforms.filter(
				(platform) => platform.status === 'APPROVED'
			)
			const uniquePlatforms: envOpt[] = []
			const platformNames = new Set()

			filteredPlatforms.forEach((platform) => {
				if (!platformNames.has(platform.platformName)) {
					platformNames.add(platform.platformName)
					uniquePlatforms.push({
						label: platform.platformName,
						value: platform.platformID
					})
				}
			})

			const extractedOptions = uniquePlatforms

			setPlatformOptions(extractedOptions)
		}
	}, [formValues.environment.value, platforms])

	const currentPlatformName = formValues.platformName.label

	useEffect(() => {
		setValue('environment', '')
		if (envOptions.length === 1) {
			// Set the environment value to the first item in the options array
			setValue('environment', envOptions[0].value)
		}
		if (platforms && platforms.length > 0) {
			const filteredPlatforms = platforms.filter(
				(platform) =>
					platform.status === 'APPROVED' &&
					platform.platformName === currentPlatformName
			)
			const extractedOptions = filteredPlatforms.map((platform) => ({
				label: platform.environment,
				value: platform.environment
			}))
			setEnvOptions(extractedOptions)
		}
	}, [currentPlatformName, platforms, setValue])

	const [isEphemeralActive, setIsEphemeralActive] = useState(false)

	const getRandomNumber = () => {
		return Math.floor(Math.random() * 100).toString()
	}

	useEffect(() => {
		setSubmitValue(false)
		const isEphemeral = checkEphemeral(formValues.applicationName)
		if (isEphemeral) {
			setValue('sourceEnvName', formValues.environment.value)
			setFormValues('sourceEnvName', formValues.environment.value)

			let existingEphemeralNumbers = getExistingEphNumbers(
				formValues.applicationName
			)
			let randomNumber = getRandomNumber()

			while (existingEphemeralNumbers.includes(randomNumber)) {
				randomNumber = getRandomNumber()
			}

			const newEnvName = formValues.environment.value + randomNumber
			setAdditionalEnvName(newEnvName)
			setValue('additionalEnvName', newEnvName)
			setFormValues('additionalEnvName', newEnvName)
			setSubmitValue(false)
		}
		setIsEphemeralActive(isEphemeral)
	}, [
		formValues.environment.value,
		formValues.platformName.label,
		formValues.applicationName,
		applications
	])

	const getExistingEphNumbers = useCallback((appName: string) => {
		let twoDigitNumbers = []

		if (applications) {
			for (let i = 0; i < applications.length; i++) {
				const details = applications[i].details

				if (details.applicationName === appName) {
					if (
						details.additionalEnvName &&
						/\d{2}$/.test(details.additionalEnvName)
					) {
						const twoDigits = details.additionalEnvName.slice(-2)
						twoDigitNumbers.push(twoDigits)
					}
				}
			}
		}

		return twoDigitNumbers
	}, [applications])

	// function getExistingEphNumbers(appName: string) {
	// 	let twoDigitNumbers = []

	// 	if (applications) {
	// 		for (let i = 0; i < applications.length; i++) {
	// 			const details = applications[i].details

	// 			if (details.applicationName === appName) {
	// 				if (
	// 					details.additionalEnvName &&
	// 					/\d{2}$/.test(details.additionalEnvName)
	// 				) {
	// 					const twoDigits = details.additionalEnvName.slice(-2)
	// 					twoDigitNumbers.push(twoDigits)
	// 				}
	// 			}
	// 		}
	// 	}

	// 	return twoDigitNumbers
	// }

	useEffect(() => {
		if (isEphemeralActive) {
			let existingEphemeralNumbers = getExistingEphNumbers(
				formValues.applicationName
			)
			let randomNumber = getRandomNumber()

			while (existingEphemeralNumbers.includes(randomNumber)) {
				randomNumber = getRandomNumber()
			}

			const newEnvName = formValues.environment.value + randomNumber
			setAdditionalEnvName(newEnvName)
			setValue('additionalEnvName', newEnvName)
			setFormValues('additionalEnvName', newEnvName)
		} else {
			setValue('additionalEnvName', '')
			setFormValues('additionalEnvName', '')
		}
	}, [isEphemeralActive, formValues.applicationName, formValues.environment.value, getExistingEphNumbers, setFormValues, setValue])

	const checkEphemeral = (applicationName: string) => {
		if (applications) {
			return applications.some((app) => {
				if (app.details.applicationName === applicationName) {
					if (
						app.details.environment.value === formValues.environment.value &&
						app.details.platformName.label === formValues.platformName.label
					) {
						if (app.status === 'APPROVED') {
							setValue('costCenter', app.details.costCenter)
							setFormValues('costCenter', app.details.costCenter)
							setValue('wbsCode', app.details.wbsCode)
							setFormValues('wbsCode', app.details.wbsCode)
							setValue('applicationAlias', app.details.applicationAlias)
							setFormValues('applicationAlias', app.details.applicationAlias)
							setValue('applicationAlias', app.details.applicationAlias)
							setFormValues('applicationAlias', app.details.applicationAlias)
							setValue(
								'applicationViewer',
								app.details.applicationViewer.join(',')
							)
							setFormValues(
								'applicationViewer',
								app.details.applicationViewer.join(',')
							)

							return true
						} else {
							setSubmitValue(true)
						}
					}
				}
				return false
			})
		}
		return false
	}

	function checkPlatformValues(platformName: any, env: any) {
		let updatedID = ''
		if (platforms) {
			const filteredPlatforms = platforms.filter(
				(platform) =>
					platform.status === 'APPROVED' &&
					platform.platformName === platformName.label
			)
			filteredPlatforms.map((platform) => {
				if (platform.environment === env) {
					setFormValues('platformName', {
						label: platformName.label,
						value: platform.platformID
					})
					setValue('platformName', {
						label: platformName.label,
						value: platform.platformID
					})
					updatedID = platform.platformID
				}
			})
			return updatedID
		}
	}

	useEffect(() => {
		const getAndSetTags = async (tagsID: string) => {
			let response = api.tags.getTagsById(tagsID)
			let body = (await response).body
			let tag = body[0] as TagsDetails

			console.log(body)
			console.log(tag)

			setTagFormValues('platformName', tag.details?.platformName)
			setTagFormValues('systemAlias', tag.details?.systemAlias)
			setTagFormValues('group', tag.details?.group)
			setTagFormValues('fundingSourceType', tag.details?.fundingSourceType)
			setTagFormValues('WBSCode', tag.details?.WBSCode)
			setTagFormValues('costCenter', tag.details?.costCenter)

			setFormValues('wbsCode', tag.details?.WBSCode)
			setFormValues('costCenter', tag.details?.costCenter)
		}

		if (formValues.platformName !== undefined && (platforms !== undefined) && isInheritTagsChecked) {
			let selectedPlatformID = formValues.platformName.value
			let selectedPlatform = platforms.filter(({ platformID }) => (platformID === selectedPlatformID))[0]

			if (selectedPlatform !== undefined) {
				if (selectedPlatform.tagsID !== undefined) {
					getAndSetTags(selectedPlatform.tagsID)
				}
			}
		}
		else {
			clearTagFormValues()

			setFormValues('wbsCode', '')
			setFormValues('costCenter', '')
		}
	},[formValues.platformName, platforms, isInheritTagsChecked, setFormValues, setTagFormValues, clearFormValues, clearTagFormValues])

	useEffect(() => {
		if (tagFormValues.WBSCode !== "") {
			setFormValues('wbsCode', tagFormValues.WBSCode)
		}

		if (tagFormValues.costCenter !== "") {
			setFormValues('costCenter', tagFormValues.costCenter)
		}
	},[tagFormValues.WBSCode, tagFormValues.costCenter, setFormValues])

	useEffect(() => {
		if (formValues.platformOwner !== "") {
			setTagFormValues('platformOwner', formValues.platformOwner)
		}

		if (formValues.applicationAlias !== "") {
			setTagFormValues('systemAlias', formValues.applicationAlias)
		}
	}, [formValues, setTagFormValues])

	// useEffect(() => {
	// 	console.log(isEphemeralActive)
	// 	console.log(formValues)
	// },[isEphemeralActive, formValues])

	const handleSubmit = async () => {
		let appRequestObj = { ...formValues }
		if (envOptions.length > 1) {
			let updatedPlatformID = checkPlatformValues(
				formValues.platformName,
				formValues.environment.value
			)

			appRequestObj = {
				...appRequestObj,
				platformName: {
					...appRequestObj.platformName,
					value: updatedPlatformID
				}
			}
		}
		await trigger()
		if (!isValid) return
		
		
		let tagsId = uuidv4()

		let newTags: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const application: ApplicationRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			action: 'CREATE',
			details: appRequestObj,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}
		

		createTags(newTags, {
			onSuccess: () => {
				createNewApplication(application, {
					onSuccess: () => {
						navigate('/applications')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}

	return (
		<div>
			{showForm ? (
				<div>
					<Form
						actions={
							<SpaceBetween direction="horizontal" size="xs">
								<Box>
									<Button
										variant="primary"
										onClick={handleSubmit}
										loading={isLoading || isCreateTagsLoading}
										disabled={submitValue}
									>
										Submit
									</Button>
									<Button 
										variant="link" 
										onClick={() => navigate(-1)}>
										Cancel
									</Button>
								</Box>
							</SpaceBetween>
						}
						header={
							<Header variant="h1" description="">
								Request a New Application Environment
							</Header>
						}
					>
						<SpaceBetween direction="vertical" size="s">
							<Container>
								<SpaceBetween direction="vertical" size="l">
									<div style={{ maxWidth: 660 }}>
										<FormInput
											name="applicationName"
											label="Application Name"
											placeholder="Enter Application Name"
											type="text"
											control={control}
											setFormValues={setFormValues}
											description="Maximum 10 alphanumeric characters allowed"
											rules={{
												required: `Please enter the application name`,
												pattern: {
													value: /^[a-z0-9_]+(?:\s*[a-z0-9_]+)*$/,
													message:
														'Uppercase letters, whitespaces are not allowed.'
												},
												maxLength: {
													value: 10,
													message: 'Maximum length should be 10'
												}
											}}
										/>
									</div>

									<div style={{ maxWidth: 660 }}>
										<FormSelect
											label="Platform Name"
											description="Select from the list of onboarded platforms (GKE Clusters)"
											name="platformName"
											control={control}
											rules={{
												required: 'Please Select Option'
											}}
											setFormValues={setFormValues}
											options={platformOptions}
											placeholder="Select the platform"
										/>
									</div>

									<div style={{ maxWidth: 660 }}>
										<Checkbox
											checked={isInheritTagsChecked}
											onChange={({detail}) => setInheritTagsChecked(detail.checked)}
											disabled={!formValues.platformName}>
												Use Platform Tags
											</Checkbox>
									</div>

									<div style={{ maxWidth: 660 }}>
										<FormSelect
											label="Environment"
											description="These are the available GKE cluster environments for your GKE cluster"
											name="environment"
											control={control}
											rules={{
												required: 'Please Select Option'
											}}
											setFormValues={setFormValues}
											options={envOptions}
											placeholder="Select Environment"
										/>
									</div>

									<div style={{ maxWidth: 660 }}>
										<FormInput
											name="applicationAlias"
											label="Application Alias"
											type="text"
											control={control}
											placeholder="Provide the Application Alias"
											description="Alias for the GKE Cluster resources to be provisioned"
											setFormValues={setFormValues}
											disabled={isEphemeralActive}
											rules={{
												required: 'Please enter Application Alias',
												minLength: {
													value: 5,
													message: 'Minimum length is 5 characters'
												},
												pattern: {
													value: /^[a-z0-9-]+$/,
													message: 'Application alias should be lowercase only'
												}
											}}
										/>
									</div>

									<div style={{ maxWidth: 660 }}>
										<FormInput
											name="applicationViewer"
											label="Application Viewer(s)"
											description="Email addresses of Application viewers (separated by commas)"
											type="text"
											control={control}
											placeholder="Provide the email addresses of individuals who should have viewer-level permissions for the project"
											setFormValues={setFormValues}
											disabled={isEphemeralActive}
											rules={{
												required: 'Please enter email address',
												pattern: {
													value:
														/^[a-z0-9._%+-]+@globe\.com\.ph(?:,[a-z0-9._%+-]+@globe\.com\.ph)*$/,
													message:
														'Please enter valid email addresses separated by commas, without any whitespaces, and ending with @globe.com.ph.'
												}
											}}
										/>
									</div>
									<div style={{maxWidth: 660}}>
										<FormInput
											name="platformOwner"
											label="Platform Owner Name"
											type="text"
											control={control}
											setFormValues={setFormValues}
											placeholder="Enter Platform Owner"
											description="Name of the Platform Owner"
											rules={{
												required: 'Please enter name of platform owner',
												pattern: {
													value: /^[a-z-]+$/,
													message: 'Plaform Owner should be lowercase only and contain no numbers and whitespaces.'
												}
											}}/>
									</div>
									<div style={{ maxWidth: 660 }}>
										<FormInput
											name="platformOwnerEmail"
											label="Platform Owner Email"
											type="email"
											control={control}
											setFormValues={setFormValues}
											description="To be used for the notification of the platform owner"
											placeholder="Enter Platform Owner Email"
											rules={{
												required: 'Please enter platform owner email',
												pattern: {
													value: /^[a-z0-9._%+-]+@globe\.com\.ph/,
													message: 'Please enter a valid Globe Telecom email address'
												}
											}} />
									</div>
									{isEphemeralActive && (
										<>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<h3 style={{ marginRight: '10px' }}>
													Additional Environment Details
												</h3>
												<Popover
													fixedWidth
													position="right"
													triggerType="custom"
													content={
														<p>
															To learn more about ephemeral environments, please
															click the link below.
															<br></br>
															<a
																href="https://compass.globe.com.ph/latest/gcp/user-guide/asp/developer-enablement/application-onboarding/ephemeral_environment/"
																target="_blank"
																rel="noopener noreferrer"
																aria-label="Plus"
															>
																Ephemeral Environments
															</a>
														</p>
													}
												>
													<Icon name="status-info" />
												</Popover>
											</div>
											<div style={{ maxWidth: 660 }}>
												<FormInput
													label="Source Environment"
													description="Select from the list of Environments"
													name="sourceEnvName"
													control={control}
													rules={{
														required: 'Please Select Option'
													}}
													setFormValues={setFormValues}
													disabled={true}
													placeholder="Select the Source Environment"
												/>
											</div>
											<div style={{ maxWidth: 660 }}>
												<FormInput
													name="additionalEnvName"
													label="Additional Environment Name"
													placeholder="Enter Additional Environment Name"
													type="text"
													control={control}
													setFormValues={setFormValues}
													description="Maximum 10 alphanumeric characters allowed"
													disabled={true}
												/>
											</div>
										</>
									)}
								</SpaceBetween>
							</Container>
							<Container 
								header={<Header variant='h3'>Tag Details</Header>}>
									<GcpApplicationTagsNew/>
							</Container>
						</SpaceBetween>
					</Form>
				</div>
			) : (
				<div>
					<h2>Do you already have a platform onboarded?</h2>
					<Button
						variant="primary"
						onClick={handleYesClick}
						loading={isLoading}
					>
						Yes
					</Button>

					<Button onClick={handleNoClick} loading={isLoading}>
						No
					</Button>
				</div>
			)}
		</div>
	)
}

export default NewApplicationForm

import { Container, Button, ColumnLayout, Form, SpaceBetween } from "@cloudscape-design/components"
import { useOktaAuth } from "@okta/okta-react"
import { FC, useState } from "react"
import { ProjectResourceApprovalRequest } from "../../../../../types/projectResource"
import { useParams } from "react-router-dom"
import useAcceptProjectResource from "../../../../../hooks/mutate/useAcceptProjectResource"
import FormInput from "../../../../../components/ControlledFormInputs/Input"
import { useApprovalFormStore } from "../../../../../stores/projects/projectResource/approvalFormStore"
import { useForm } from "react-hook-form"

interface FormProps {
  showForm: (showForm: boolean) => void
}

export const ApprovalForm: FC<FormProps> = ({showForm}) => {
  const { authState } = useOktaAuth()
  const thisUserEmail = authState?.idToken?.claims.email as string
  const { resourceId } = useParams() as { resourceId: string }

  const {mutate: acceptSslCert, isLoading} = useAcceptProjectResource(resourceId)

  const {
    formValues,
    actions: {
      clearFormValues,
      setFormValues
    }
  } = useApprovalFormStore()

  const {
    control, trigger, setValue,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleApprove = () => {
    trigger()
    if (isValid) {
      const approvalRequest: ProjectResourceApprovalRequest = {
        projectResourceID: resourceId,
        approverID: thisUserEmail,
        timestamp: new Date().toISOString(),
        details: formValues,
        action: 'APPROVE',
        resourceType: 'SSL_CERT'
      }
  
      console.log(approvalRequest)
  
      acceptSslCert(approvalRequest, {
        onSuccess: ({data}) => {
          console.log(data)
          showForm(false)
          clearFormValues()
        }
      })
    }
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={() => showForm(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleApprove} loading={isLoading}>Approve</Button>
        </SpaceBetween>
      }>
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <ColumnLayout columns={1}>
              <FormInput
                name="moduleFileName"
                label="Module Filename"
                control={control}
                setFormValues={setFormValues}
                placeholder="example-dns.tf"
                rules={{
                  required: 'Please enter name of file known to contain AWS DNS Module'
                }}/>
            </ColumnLayout>
          </SpaceBetween>
        </Container>
      </Form>
  )
}
import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { MicroserviceApprovalRequest } from '../../types/microservices'

const { MICROSERVICES_API_URL } = CONSTANT

const acceptMicroservice = async (
	microserviceApproval: MicroserviceApprovalRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${MICROSERVICES_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'APPROVE'
			},
			body: JSON.stringify(microserviceApproval)
		}
	)
	return response.json()
}

export default acceptMicroservice

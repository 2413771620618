import { FC, useEffect, useState } from 'react'
import {
	Box,
	ButtonDropdown,
	ContentLayout,
	Grid,
	Header,
	SpaceBetween,
	Container
} from '@cloudscape-design/components'
import { useNavigate, useParams } from 'react-router-dom'
import useSingleProjectResource from '../../../../../hooks/query/useSingleProjectResource'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { ProjectResource } from '../../../../../types/projectResource'
import HeaderWithStatusBadges from '../../../../../components/Common/HeaderWithStatusBadges'
import RenderSslCertFields from './RenderSslCertFields'
import { useOktaAuth } from '@okta/okta-react'
import RequiredPermissions from '../../../../../helper/RequiredPermissions'
import { ApprovalForm } from './ApprovalForm'
import { RejectionForm } from './RejectionForm'
import CommentsOverview from '../../../../../components/Project/CommentsOverview'
import ApproverOverview from '../../../../../components/ProjectResource/ApprovalOverview'

const getKeyValueSslCertResource = (resource: ProjectResource) => {
	return [
		{
			label: 'Domain Name',
			value: resource.details.domainName
		},
		{
			label: 'Endpoint Name',
			value: resource.details.endpointName
		},
		{
			label: 'Account Distribution List',
			value: resource.details.accountDistributionList
		}
	]
}

const SslCertDetails: FC = () => {
	const { resourceId } = useParams() as { resourceId: string }

	const { authState } = useOktaAuth()
	const CURRENT_USER_ID = authState?.idToken?.claims.email as string

	const navigate = useNavigate()

	const { data, isLoading } = useSingleProjectResource(resourceId)

	const [showApprovalForm, setShowApprovalForm] = useState(false)
	const [showRejectionForm, setShowRejectionForm] = useState(false)

	useEffect(() => {
		console.log(`approval show ${showApprovalForm}\nreject show ${showRejectionForm}`)
	}, [showApprovalForm, showRejectionForm])

	if (isLoading) {
		return <LoadingSpinner />
	}

	if (data === undefined) {
		navigate(-1)
	}

	if (data?.statusCode !== 200) {
		let message = data?.body as { message: string }
		return (
			<>
				<h1>Error {data?.statusCode}</h1>
				<p>{message.message}</p>
			</>
		)
	}

	let sslCertDetails = data?.body as ProjectResource
	let awsAccountName = sslCertDetails.details.accountDistributionList
		.split('@')[0]
		.split('-')
		.splice(1, 4)
		.join('-')
	let parsedDetails = getKeyValueSslCertResource(sslCertDetails)

	let isAllTagsApproved = false
	if (sslCertDetails.approvalStatus !== undefined) {
		isAllTagsApproved = true
		for (const approval of sslCertDetails.approvalStatus) {
			if (approval.status !== 1) {
				isAllTagsApproved = false
			}
		}
	}

	return (
		<ContentLayout
			header={
				<HeaderWithStatusBadges
					headerName={`${sslCertDetails.details.domainName}.${awsAccountName}.globetel.cloud`}
					status={sslCertDetails.status}
				/>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 3, xxs: 9 } },
					{ colspan: { default: 9, xxs: 3 } }
				]}
			>
				<SpaceBetween direction="vertical" size="l">
					<RenderSslCertFields sslCert={parsedDetails} />

					{(sslCertDetails.status === 'PENDING_APPROVAL' && isAllTagsApproved &&
						CURRENT_USER_ID !== sslCertDetails.details.requestorID) ? (
							<RequiredPermissions requiredPermissions={['projects:*:read', 'projects:*:approve']}>
								<Box float="left">
									<ButtonDropdown
										items={[
											{text: 'Approve', id: 'approve'},
											{text: 'Reject', id: 'reject'}
											]}
											onItemClick={({detail}) => {
												if (detail.id === 'approve') {
													setShowApprovalForm(true)
													setShowRejectionForm(false)
												}

												if (detail.id === 'reject') {
													setShowApprovalForm(false)
													setShowRejectionForm(true)
												}
											}}>
										Action
									</ButtonDropdown>
								</Box>
							</RequiredPermissions>
						) : (
							<ApproverOverview approvalStatus={sslCertDetails.approvalStatus} projectResourceID={resourceId}/>
						)}

					
					{showApprovalForm && (
						<ApprovalForm showForm={setShowApprovalForm}/>
					)}
					{showRejectionForm && (
						<RejectionForm showForm={setShowRejectionForm}/>
					)}
				</SpaceBetween>
				<SpaceBetween direction="vertical" size="l">
					<Container header={
						<Header variant="h3">Comment History</Header>
					}>
						<CommentsOverview comments={sslCertDetails.comments || []}/>
					</Container>
				</SpaceBetween>
			</Grid>
		</ContentLayout>
	)
}

export default SslCertDetails

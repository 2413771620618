import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { ApiApprovalRequest } from '../../types/apis'
import api from '../../api'

const useAcceptApi = (apiID: string) => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (apiApproval: ApiApprovalRequest) => {
			return api.apis.acceptApi(apiApproval)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['microservices'] })
			queryClient.invalidateQueries({
				queryKey: ['apis', apiID]
			})
		}
	})
}

export default useAcceptApi

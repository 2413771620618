import { Button, ColumnLayout, Form, SpaceBetween, Textarea } from "@cloudscape-design/components"
import { useOktaAuth } from "@okta/okta-react"
import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import useRejectProjectResource from "../../../../../hooks/mutate/useRejectProjectResource"
import { ProjectResourceRejectionRequest } from "../../../../../types/projectResource"

interface FormProps {
  showForm: (showForm: boolean) => void
}

export const RejectionForm: FC<FormProps> = ({showForm}) => {
  const { authState } = useOktaAuth()
  const thisUserEmail = authState?.idToken?.claims.email as string
  const { resourceId } =  useParams() as { resourceId: string }

  const { mutate: rejectSslCert, isLoading } = useRejectProjectResource(resourceId)
  const [comment, setComment] = useState('')

  const handleReject = () => {
    const rejectRequest: ProjectResourceRejectionRequest = {
      projectResourceID: resourceId,
      commenterID: thisUserEmail,
      comment: comment,
      timestamp: new Date().toISOString(),
      action: 'REJECT',
      resourceType: 'SSL_CERT'
    }

    if (comment.length > 0) {
      rejectSslCert(rejectRequest, {
        onSuccess: () => {
          showForm(false)
          setComment('')
        }
      })
    }
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={() => showForm(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleReject}>Reject</Button>
        </SpaceBetween>
      }>
        <SpaceBetween direction="vertical" size="l">
          <Textarea onChange={({detail}) => setComment(detail.value)} value={comment}/>
        </SpaceBetween>
      </Form>
  )
}
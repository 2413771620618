import { useQuery } from '@tanstack/react-query'

import type { Api } from '../../types/apis'
import api from '../../api'

const useSingleApi = (apiID: string) => {
	return useQuery({
		queryKey: ['apis', apiID],
		queryFn: () => api.apis.getSingleApi(apiID, true),
		select(data) {
			if (data.body !== undefined) {
				const response = sortComments(data.body[0])
				return response as Api
			} else {
				let response = {} as Api
				response['status'] = 'ERROR'
				response['details'] = data
				return response
			}
		}
	})
}

const sortComments = (response: Record<any, any>) => {
	let sortedCommentResponse = JSON.parse(JSON.stringify(response))
	let comments = (response && response.comments) || []
	sortedCommentResponse['comments'] = comments.sort(
		(a: any, b: any) =>
			new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
	)
	return sortedCommentResponse
}

export default useSingleApi

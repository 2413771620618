import { useQuery } from "@tanstack/react-query"
import api from "../../api"

const useSingleProjectResource = (projectResourceID: string) => {
  return useQuery({
    queryKey: ['projectResource', projectResourceID],
    queryFn: () => api.projects.resources.getSingleProjectResource(projectResourceID),
    select(data) {
      return data
    }
  })
}

export default useSingleProjectResource
import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { MicroserviceEditRequest } from '../../types/microservices'

const { MICROSERVICES_API_URL } = CONSTANT

const editMicroservice = async (
	editMicroservice: MicroserviceEditRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${MICROSERVICES_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'UPDATE'
			},
			body: JSON.stringify(editMicroservice)
		}
	)

	return response.json()
}

export default editMicroservice

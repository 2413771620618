import { FC } from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import {
	Box,
	ColumnLayout,
	Container,
	Grid,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import type { Api } from '../../../types/apis'

interface ApprovalDetailsProps {
	approvalDetails: Api
}

const ApprovalDetails: FC<ApprovalDetailsProps> = ({ approvalDetails }) => {
	const jsonDataString = JSON.stringify(approvalDetails)
	const jsonData = JSON.parse(jsonDataString)

	// Commenting to update here
	const generalDetailsKeyValuePairsItems = [
		[{ label: 'Platform Cluster Name', value: jsonData.namePrefix }],
		[
			{
				label: 'Network Project ID',
				value: jsonData.networkProjectID
			}
		],
		[{ label: 'VPC Name', value: jsonData.network }],
		[{ label: 'Subnet Name', value: jsonData.subnetwork }],
		[
			{
				label: 'Control Plane Range',
				value: jsonData.controlPlaneRange
			}
		],
		[
			{
				label: 'Node Subnet Range',
				value: jsonData.subnetworkRange
			}
		],
		[
			{
				label: 'Name of Secondary Ranges for Pods',
				value: jsonData.ipRangePods
			}
		],
		[
			{
				label: 'Name of Secondary Ranges for Services',
				value: jsonData.ipRangeServices
			}
		]
	]

	return (
		<Container>
			<Header variant="h3">Provisioning Details</Header>
			<ColumnLayout columns={3} variant="text-grid">
				<KeyValuePairs
					key={'generalDetails'}
					items={generalDetailsKeyValuePairsItems}
				/>
			</ColumnLayout>
		</Container>
	)
}

export default ApprovalDetails

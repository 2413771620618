import CONSTANTS from "../../../constants";
import fetchWithAuthorizationHeader from "../../../helper/fetchWithAuthorizationHeader";
import { ProjectResource } from "../../../types/projectResource";

const { PROJECT_RESOURCE_API_URL } = CONSTANTS

const getProjectResources = async(projectId: string): Promise<{statusCode: number, body: Array<ProjectResource>}> => {
  const response = await fetchWithAuthorizationHeader(`${PROJECT_RESOURCE_API_URL}?projectID=${projectId}`)
  if (response.status !== 200) {
    return {
      statusCode: response.status,
      body: []
    }
  }

  return response.json()
}

export default getProjectResources

const PORTFOLIO_LIST = [
	{ label: 'Product Engineering and Digital Growth : pedg', value: 'pedg' },
	{ label: 'Business to Business : dsg', value: 'dsg' },
	{ label: 'Enterprise Data Strategic Services. Formerly EDO : eds', value: 'eds' },
	{ label: 'ISDP : sdp', value: 'sdp' },
	{ label: 'ISG CESO : isg', value: 'isg' },
	{
		label: 'ISG Enterprise Integration Services : isg',
		value: 'isg'
	},
	{ label: 'ISG Enterprise Services : isg', value: 'isg' },
	{
		label: 'ISG Platform Portfolio Management  : isg',
		value: 'isg'
	},
	{ label: 'ISG Risk Management : isg', value: 'isg' },
	{ label: 'NTG : ntg', value: 'ntg' },
	{ label: 'Artificial Intelligence Group : aig', value: 'aig' },
	{ label: 'Finance : fin', value: 'fin' }
]

export default PORTFOLIO_LIST

import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'
import type { MicroserviceRejectionRequest } from '../../types/microservices'

const { MICROSERVICES_API_URL } = CONSTANT

const rejectMicroservice = async (
	microserviceRejection: MicroserviceRejectionRequest
): Promise<any> => {
	const response = await fetchWithAuthorizationHeader(
		`${MICROSERVICES_API_URL}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				action: 'REJECT'
			},
			body: JSON.stringify(microserviceRejection)
		}
	)

	return response.json()
}

export default rejectMicroservice

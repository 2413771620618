import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { APIS_API_URL } = CONSTANT

const getSingleApi = async (
	apiID: string,
	getTags: boolean = false
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${APIS_API_URL}?platformID=${apiID}&getTags=${getTags.toString()}`
	)
	return response.json()
}

export default getSingleApi

import {
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	Header,
	Popover,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'
import Icon from '@cloudscape-design/components/icon'

import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'

import { useNewApiFormStore } from '../../../stores/apis/newApiFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewApi from '../../../hooks/mutate/useCreateNewApi'
import useApprovedGcpProjects from '../../../hooks/query/useApprovedGcpProjects'
import CONSTANTS from '../../../constants'
import { gcpProject } from '../../../types/gcpProject'
import type { ApiRequest } from '../../../types/apis'
import api from '../../../api'
import { getTagsApprovalObject, TagsRequest } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'

const MICROSERVICE_LIST = [
	{ label: 'microservice1', value: 'microservice1' },
	{ label: 'microservice2', value: 'microservice2' },
	{ label: 'microservice3', value: 'microservice3' }
]

const METHOD_LIST = [
	{ label: 'GET', value: 'GET' },
	{ label: 'POST', value: 'POST' },
	{ label: 'PUT', value: 'PUT' },
	{ label: 'PATCH', value: 'PATCH' },
	{ label: 'DELETE', value: 'DELETE' }
]

const NewApiForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewApiFormStore()

	const {
		formValues: tagFormValues,
		actions: {
			setFormValues: setTagFormValues,
			clearFormValues: clearTagFormValues
		}
	} = useGenericTagsManagementFormStore()

	const { mutate: createNewApi, isLoading } = useCreateNewApi()
	const { mutate: createTags, isLoading: isCreateTagsLoading } =
		useCreateNewTags()
	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	const { data: gcpProjects, isLoading: isGcpProjectsLoading } =
		useApprovedGcpProjects()

	const [isInheritTagsChecked, setInheritTagsChecked] = useState(false)
	const [isGcpProjectIdFilled, setGcpProjectIdFilled] = useState(false)

	const [popFields, setPopFields] = useState(false)

	useEffect(() => {
		if (formValues.gcpProjectID !== undefined) {
			setGcpProjectIdFilled(true)
		} else {
			setGcpProjectIdFilled(false)
		}
	}, [formValues.gcpProjectID])

	useEffect(() => {
		const getProjectWithTags = async (gcpProjectID: {
			label: string
			value: string
		}) => {
			let response = await api.gcpProjects.getSinglegcpProject(
				gcpProjectID.value,
				true
			)
			let project = response.body[0] as gcpProject
			console.log(project)

			setTagFormValues('platformName', project.tags?.details.platformName)
			setTagFormValues('group', project.tags?.details.group)
			setTagFormValues(
				'fundingSourceType',
				project.tags?.details.fundingSourceType
			)
			setTagFormValues('WBSCode', project.tags?.details.WBSCode)
			setTagFormValues('costCenter', project.tags?.details.costCenter)
			setTagFormValues('projectName', gcpProjectID.label)

			setFormValues('wbsCode', project.tags?.details.WBSCode)
			setFormValues('costCenter', project.tags?.details.costCenter)
			setFormValues('group', project.tags?.details.group)
			setValue('group', project.tags?.details.group)
		}
		if (isInheritTagsChecked) {
			console.log(formValues.gcpProjectID)
			getProjectWithTags(formValues.gcpProjectID)
		} else {
			clearTagFormValues()

			setFormValues('wbsCode', '')
			setFormValues('costCenter', '')
			setFormValues('group', '')
			setValue('group', '')
		}
	}, [
		formValues.gcpProjectID,
		setTagFormValues,
		setValue,
		isInheritTagsChecked,
		clearTagFormValues,
		setFormValues
	])

	useEffect(() => {
		if (tagFormValues.WBSCode !== '') {
			setFormValues('wbsCode', tagFormValues.WBSCode)
		}

		if (tagFormValues.costCenter !== '') {
			setFormValues('costCenter', tagFormValues.costCenter)
		}
	}, [tagFormValues.WBSCode, tagFormValues.costCenter, setFormValues])

	useEffect(() => {
		if (formValues.platformOwner !== '') {
			setTagFormValues('platformOwner', formValues.platformOwner)
		}

		if (formValues.platformName !== '') {
			setTagFormValues('platformName', formValues.platformName)
		}

		if (formValues.systemAlias !== '') {
			setTagFormValues('systemAlias', formValues.systemAlias)
		}
	}, [formValues, setTagFormValues])

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let tagsId = uuidv4()

		let newTag: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const api: ApiRequest = {
			requestorID: thisUserEmail,
			// tagsID: tagsId,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}

		createTags(newTag, {
			onSuccess: () => {
				createNewApi(api, {
					onSuccess: () => {
						navigate('/apis')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}
	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [setValue])

	useEffect(() => {
		if (formValues?.platformType?.value === 'standard') {
			setPopFields(true)
		} else {
			setPopFields(false)
		}
	}, [formValues?.platformType?.value, setPopFields, formValues])

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading || isCreateTagsLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Create Endpoint
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<ColumnLayout columns={2} variant="default">
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<div style={{ width: '650px' }}>
									<FormSelect
										label="Microservice"
										name="microserviceName"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={MICROSERVICE_LIST}
										placeholder="Select Microservice Name"
									/>
								</div>
								<div style={{ marginLeft: '10px', marginTop: '25px' }}>
									<Popover
										fixedWidth
										header="Platform GCP Project ID tip"
										position="right"
										triggerType="custom"
										content={
											<p>
												Please see guide on how to enter your GCP Project ID:
												<a
													href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="Plus"
													style={{
														marginLeft: '10px',
														marginTop: '25px'
													}}
												>
													LINK
												</a>
											</p>
										}
									>
										<Icon name="status-info" />
									</Popover>
								</div>
							</div>
							<div></div>
							<FormInput
								name="apiName"
								label="Name"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Display Name of API"
								description="Max 20 characters"
								rules={{
									required: 'Please enter API name',
									minLength: {
										value: 5,
										message: 'Minumum length of 5 characters'
									},
									pattern: {
										value: /^[a-z0-9]+$/,
										message:
											'API name should be lowercase only and whitespaces are not allowed.'
									}
								}}
							/>
							<FormInput
								name="apiEndpoint"
								label="Path"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Endpoint Path"
								description="The endpoint URL/path"
							/>
							<FormSelect
								label="HTTP Method"
								name="httpMethod"
								control={control}
								rules={{
									required: 'Please Select Option'
								}}
								setFormValues={setFormValues}
								options={METHOD_LIST}
								placeholder="Select HTTP Method"
							/>
							<FormInput
								name="apiStatus"
								label="Status"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Design"
								disabled
							/>
						</ColumnLayout>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<label style={{ fontWeight: '600', marginBottom: '5px' }}>
								Description
							</label>
							<Textarea
								onChange={({ detail }) => setFormValues('description',detail.value)}
								name='description'
								value={formValues.description}
								placeholder="Enter description of Endpoint"
							/>
						</div>
						<ColumnLayout columns={2} variant="default">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									Request Data Types
								</label>
								<Textarea
									onChange={({ detail }) => setFormValues('requestDataTypes',detail.value)}
									value={formValues.requestDataTypes}
									placeholder="Enter Request Structure as JSON object with data type as a value"
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									Mock Request
								</label>
								<Textarea
									onChange={({ detail }) => setFormValues('mockRequest',detail.value)}
									value={formValues.mockRequest}
									placeholder="Enter Request Structure as JSON object with actual values"
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									Response Data Types
								</label>
								<Textarea
									onChange={({ detail }) => setFormValues('responseDataTypes',detail.value)}
									value={formValues.responseDataTypes}
									placeholder="Enter Response Structure JSON object with data type as a value"
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label style={{ fontWeight: '600', marginBottom: '5px' }}>
									Mock Response
								</label>
								<Textarea
									onChange={({ detail }) => setFormValues('mockResponse',detail.value)}
									value={formValues.mockResponse}
									placeholder="Enter Response Structure JSON object with actual values"
								/>
							</div>
						</ColumnLayout>
					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default NewApiForm

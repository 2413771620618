import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { MicroserviceRequest } from '../../types/microservices'
import api from '../../api'

const useCreateNewMicroservice = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (microservice: MicroserviceRequest) => {
			return api.microservices.createNewMicroservice(microservice)
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['microservices'] })
		}
	})
}

export default useCreateNewMicroservice
